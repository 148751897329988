.uiCheckboxDropdown{
	position:relative;
	width:auto;
	display:inline-block;
	margin-right:5px;
}
.uiCheckboxDropdown .options{
	display:none;
	position:absolute;
	background:#aaa;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border:none;
	padding: 5px 0 5px 10px;
	z-index:500;
	max-height:200px;
	overflow:auto;
	width:auto!important;
}
.uiCheckboxDropdown .options .option{
	line-height:18px!important;
	font-size:11px;
	padding: 0 20px 0 13px;
	margin-right:10px;
	border: solid 1px #aaa;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	cursor:pointer;
	width:auto!important;
	background-image:url(../images/sprites_horizontal_breite.png);
	background-repeat:no-repeat;
	background-position: 5px -657px;
}
.uiCheckboxDropdown .options .option:hover{
	background-color: #ccc;
	color:#000;
	border: solid 1px #ccc;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}
.uiCheckboxDropdown .options .option.active{
	background-color: #ccc;
	color:#000;
	border: solid 1px #ccc;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	background-position: 3px -628px;
}
.uiCheckboxDropdown .options input{
	display:none;
}
.uiCheckboxDropdown .opener{
	cursor:pointer;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	background-color:#CCCCCC;
	display: inline-block;
	height: 18px;
	font-size: 11px;
	line-height: 18px;
	padding: 0 3px 0 13px;
	background-image:url(../images/sprites_horizontal_breite.png);
	background-repeat:no-repeat;
	background-position: 5px -598px;
	color:#808080;
	white-space: nowrap;
}
.uiCheckboxDropdown .opener:hover{
	background-color:#aaa;
	color:#333;
	background-position: 5px -658px;
}
.uiCheckboxDropdown .opener.active{
	background-position: 3px -568px!important;
}
.uiCheckboxDropdown:hover .opener.active{
	background-position: 3px -628px!important;
}
.uiCheckboxDropdown:hover .opener.open{
	box-shadow: 0 0 2px #00abd3;
}
.uiCheckboxDropdown .opener .count{
	display:inline;
	margin-left:4px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	background:#808080;
	color:#ccc;
	padding:0 2px 0 2px;
}
.uiCheckboxDropdown .opener:hover .count{
	background:#333;
	color:#aaa;
}