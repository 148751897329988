#workbar{
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color:#eee;
  position:fixed;
  padding: 3px 10px 3px 3px;
  border:solid 1px #ccc;
  line-height:28px;
  height:auto;
  color: #808080;
  right:25px;
  z-index: 9999;
}
#workbar:hover{
  background-color: #dddddd;
  color: #333;
}
#pages{
  float:left;
  margin-right:15px;
}
#showall{
  float:left;
  margin-right:20px;
}
#export{
  float:left;
}
#prev-page{
  background-image:url(../../../../../images/sprites_main.png);
  background-repeat: no-repeat;
  background-position: -456px top;
  width:28px;
  height:28px;
  display:inline-block;
  text-indent: -1000px;
  overflow:hidden;
}
#prev-page:hover{
  background-position: -484px top;
}
#prev-page:active, #prev-focus:active{
  background-position: -512px top;
}

#next-page{
  background-image:url(../../../../../images/sprites_main.png);
  background-repeat: no-repeat;
  background-position: -568px top;
  width:28px;
  height:28px;
  display:inline-block;
  text-indent: -1000px;
  overflow:hidden;
}
#next-page:hover{
  background-position: -596px top;
}
#next-page:active, #prev-focus:active{
  background-position: -624px top;
}

#show-all{
  background-image:url(../../../../../images/sprites_main.png);
  background-repeat: no-repeat;
  background-position: -568px -28px;
  width:28px;
  height:28px;
  display:inline-block;
  text-indent: -1000px;
  overflow:hidden;
}
#show-all:hover{
  background-position: -596px -28px;
}
#show-all:active, #prev-focus:active{
  background-position: -624px -28px;
}

#show-page{
  background-image:url(../../../../../images/sprites_main.png);
  background-repeat: no-repeat;
  background-position: -680px -28px;
  width:28px;
  height:28px;
  display:inline-block;
  text-indent: -1000px;
  overflow:hidden;
}
#show-page:hover{
  background-position: -708px -28px;
}
#show-page:active, #prev-focus:active{
  background-position: -736px -28px;
}

a{
  color: #00abd3;
  text-decoration:none;
}
a:hover{
  text-decoration:underline;
}

#properties fieldset.col_50{
  float:none;
}

#properties fieldset.col_100{
  float:none;
  width: 357px;
}