$noColor:#fff;

// Grundfarben
$darkbrown:#7D6F53;
$colorCorporateBrown:#7D6F53;
$colorCorporateLightBrown:#AC9C79;
$colorCorporateTurquoise:#01aad3;
$colorCorporateLightTurquoise:#E2F1F7;

// Grau
$colorMidGray:#cccccc;
$colorDarkGray:#808080;

// Status
$colorError:#d40000;
