.Moduleholder{
	text-align:left!important;
	border-collapse: collapse;
	&.default{
		width:100%;
		font-size: 14px!important;
		line-height: 140%;
		all: unset;
		position: absolute;
		width: 100%;
		left: 0px;
		height:calc(100vH - 100px);
		overflow: auto;
		top: 100px;
	}
}
div[role=presentation]{
	text-align:left!important;
}
