/* Loginscreen */
body, html{
  // overflow:hidden;
}
.login-wrapper{
  background-image:url(../img/body_login.jpg);
  background-repeat:no-repeat;
  background-size: 100% 100%;
  width:100%;
  height:100%;
  position: absolute;
  z-index: 900;
  top:0px;
}

.loginscreen{
  margin:auto;
  width:402px;
  padding-top:300px;
  background-image:url(../img/logo_login.png);
  background-repeat:no-repeat;
  background-position: center 240px;
  label{
    color: #fff;
    font-size:13px!important;
  }
  .input{
    &.text{
      input{
        border:none;
        width:150px!important;
        &.remember{
          width:10px!important;

        }
      }
    }
  }
  fieldset{
    border-top: dotted 1px #fff!important;
    border-bottom: dotted 1px #fff!important;
    padding: 25px 60px 20px 60px;
    margin-bottom:5px;
  }
  p{
    version{
      margin-bottom:20px;
      text-align:right;
      color:grey;
    }
  }
}
.login{
  border:none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  color: #fff;
  background-color: transparent;
  border: solid 1px #fff;
  background-image:url(../img/sprites_horizontal_breite.png);
  background-repeat: no-repeat;
  background-position: left -258px;
  padding: 3px 20px 3px 20px;
  font-size: 13px;
  margin-left:182px;
  cursor: pointer;
  &:disabled{
    opacity: 0.3;
  }
}
.login:hover{
  box-shadow: 0 0 2px #fff;
}

.login:active, .login:focus{
  background-position: left -282px!important;
  box-shadow: none;
}

.loginscreen .input.checkbox .option{
  background: #fff;
  color: #000;
  font-size:10px;
  line-height:16px;
  padding: 2px 5px 2px 18px;
  opacity:0.5;
  background-position: 8px -477px;
  background-image:url(../img/sprites_horizontal_breite.png);
}
.loginscreen .checkboxGroup{
  padding:0!important; margin:0!important;
}


.loginscreen .input.checkbox .option.active{
  background-repeat: no-repeat;
  background-position: 7px 6px;
  background-color: #00abd3;
  background-image:url(../img/haken_weiss.png);
  opacity:1.0;
  color:#fff;
}
