#preselectdate .uiSelect {
    width: 140px !important;
    margin-left: 20px;
}

.input {
    &.text {
        .uiSelect {
            margin-left: 123px;
            position: relative;
        }
        button {
            border: solid 1px #7d6f53;
            font-size: 13px;
            background: #ac9c79;
            padding: 0 5px 0 5px;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            height: 26px;
            float: right;
            color: #fff;
            text-align: left;
            background-image: url(../images/sprites_horizontal_breite.png);
            background-repeat: no-repeat;
            background-position: right -106px;
            width: 100%;
        }
    }
}
.input.text.gray .uiSelect button {
    border: solid 1px #333;
    padding: 0 5px 0 5px;
    height: 26px;
    float: right;
    color: #333;
    background-image: url(../images/sprites_horizontal_breite.png);
    background-repeat: no-repeat;
    background-position: right -393px;
    font-size: 12px;
    line-height: 26px\0 / IE9;
}
.input.text .uiSelect button:active {
    background-color: #7d6f53;
    border: solid 1px #ac9c79;
    color: #fff;
}
.input.text.gray .uiSelect button:active {
    background-position: right -417px;
}
.input.text .uiSelect button.active {
    box-shadow: 0 0 2px #00abd3;
    background-position: right -170px;
    background-color: #7d6f53;
    color: #fff;
}
.input.text.gray .uiSelect button.active {
    background-position: right -417px;
    color: #333;
}
.input.text .uiSelect ul {
    list-style-type: none;
    background: #fff;
    border: solid 1px #7d6f53;
    position: absolute;
    display: none;
    padding: 10px 0 10px 10px;
    z-index: 500;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    max-height: 200px;
    overflow: auto;
}
.input.text.gray .uiSelect ul {
    border: solid 1px #333;
    background-color: #eee;
}
.input.text .uiSelect ul li {
    margin-right: 10px;
    line-height: 22px !important;
    font-size: 12px;
    padding-left: 5px;
    border: solid 1px #fff;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    cursor: pointer;
}
.input.text.gray .uiSelect ul li {
    color: #333;
    border: solid 1px #eee;
}
.input.text .uiSelect ul li:hover,
.input.text .uiSelect ul li {
    &.choosen {
        background: #eaf1f7;
        color: #333;
        border: solid 1px #6cbbd8;
        border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
    }
    &.hidden {
        display: none;
    }
}

fieldset.col_30 .input.text .uiSelect button {
    width: 95px;
    margin-right: 10px;
}
fieldset.col_70 .input.text .uiSelect button {
    width: 352px;
    margin-right: 10px;
}
fieldset.col_50 .input.text .uiSelect button {
    width: 224px;
    margin-right: 10px;
}

fieldset.col_30 .input.text .uiSelect button {
    width: 95px;
    margin-right: 10px;
}
fieldset.col_70 .input.text .uiSelect button {
    width: 352px;
    margin-right: 10px;
}
fieldset.col_50 .input.text .uiSelect button {
    width: 224px;
    margin-right: 10px;
}

.uiSelect .trenner {
    font-size: 9px !important;
    border-bottom: solid 1px #fff !important;
    border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    padding: 0 !important;
    height: 1px;
    margin: 3px 0 5px 0;
    cursor: default !important;
}
.uiSelect .trenner:hover {
    background: none !important;
    cursor: default !important;
}
.uiSelect .trenner span {
    height: 2px;
    line-height: 1px;
    float: left;
    position: absolute;
    margin-left: -1px;
    padding: 0 5px 0 0px;
}
#kopf .uiSelect .trenner span {
    background: #7fd5e9;
    color: #fff;
}
/* Suchleiste */
#suchleiste .input.text {
    margin: 0px;
}
#suchleiste .input.text .uiSelect {
    margin: 6px 5px 5px 6px;
    width: 110px;
    float: left;
    height: 16px;
}

#fieldsetProtocolMessage .input.text .uiSelect {
    width: 180px;
}

#suchleiste .input.text .uiSelect button {
    background-color: #cccccc;
    background-position: right -364px;
    border: 1px #cccccc solid;
    color: #808080;
    font-size: 11px;
    height: 18px;
    overflow: hidden;
}
#suchleiste:hover .input.text .uiSelect button {
    color: #333;
    background-color: #aaa;
}

#suchleiste .input.text .uiSelect ul {
    list-style-type: none;
    background: #aaa;
    border: none;
    position: absolute;
    display: none;
    padding: 5px 0 5px 10px;
    z-index: 500;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    max-height: 200px;
    overflow: auto;
}

#suchleiste .input.text .uiSelect ul li {
    margin-right: 10px;
    line-height: 18px !important;
    font-size: 11px;
    padding-left: 5px;
    border: solid 1px #aaa;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    cursor: pointer;
    overflow: hidden;
}
#suchleiste .input.text .uiSelect ul li:hover,
.input.text .uiSelect ul li.choosen {
    background: #ccc;
    color: #000;
    border: solid 1px #ccc;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
#suchleiste .input.text label {
    margin-top: 11px;
    width: auto;
}
