/* Date from to */
.componentDate .fastselect{
	font-size:11px;
	color:#808080;
	line-height:17px;
	margin: 20px 0px !important; 
}
.componentDate .fastselect:hover{
	color:#333;
}
.componentDate .fastselect a{
	color:#808080;
	background-color:#cccccc;
	-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;
	padding:0 5px 0 10px;
	background-image:url('../images/sprites_horizontal_breite.png');
	background-position:3px -684px;
}
.componentDate .fastselect:hover a{
	color:#333;
	background-color:#aaa;
	background-position:3px -704px;
}
.componentDate .fastselect a:hover{
	text-decoration:none;
	background-color:#00abd3;
	color:#fff;
	background-position:3px -724px;
}
.componentDate .uiSelect{
	margin-left:0px!important;
}
.componentDate .input.checkbox{
	clear:none;
	display:table-cell;
	vertical-align:top;
	white-space:nowrap;
	padding-right:20px;
}
.componentDate .fixDate .input.text{
	width:140px;
	float:left;
	clear:none;
	margin-right:10px;
}
.componentDate .fastselect label{
	padding-right:10px;
}
.componentDate .variableDate{
	width:180px;
	
}
