@charset "UTF-8";
/* CSS Document */
@import '~@mabinso-registry/module-commons/src/css/_mabinso';
@import '_images';
@import '_standardTabelle';
@import '_kopf';
@import '_help';
@import '_overlays';
@import '_reports';
@import '_uiSelect';
@import '_uiCheckboxDropdown';
@import '_searchbar';
@import '_buttons';
@import '_componentDate';
@import '_animations';
@import '_icons';
@import '_form';
@import '_validationEngine';

@import '_jquery.tagsinput';
@import '_logs';
@import '_interfaces';
@import '_interfacerun_view';
@import '_jquery-ui-custom';
* {
    padding: 0;
    margin: 0;
}
html,
body {
    color: #333;
    background-image: url(../images/bg_body.png);
    background-repeat: repeat-x;
    background-color: $noColor;
    background-position: bottom left;
    background-attachment: fixed !important;
    text-align: center;
    height: 100%;
}
#imagepreload {
    position: absolute;
    left: -100000px;
}
#hauptrahmen {
    text-align: left;
    position: relative;
    width: 1098px;
    margin: 0 auto;
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto;
}
#routerLoader {
    background: rgba(4, 10, 23, 0.5);
    left: 50%;
    width: 100vw;
    height: 'calc(100vh - 100px)';
    position: absolute;
    top: 100px;
    transform: translate(-50%, 0);
}
/* Globale */
.tableCell {
    display: table-cell;
}
.reportCell {
    margin-top: 10px !important;
    display: flex !important;
    grid-gap: 10px !important;
    flex-wrap: wrap !important;
}
button {
    font-family: 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', 'Tahoma';
}
.contentAjaxLoad {
    display: none;
}
.clear {
    clear: both;
}
a {
    color: $colorCorporateTurquoise;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

//DAS Muss Raus, ist im Moment aber auch im Editor Modul, warum?
td {
    font-size: 11px !important;
}

.defaultButton {
    @extend .standardButton;
}
.defaultButton:hover {
    box-shadow: none;
}
.buttonS {
    width: 80px;
    height: 26px;
}
.buttonFlatXS {
    width: 50px;
    height: 22px;
}

.buttonFlatS {
    width: 86px;
    height: 28px;
    background-position: -344px 0px;
    border: 0px;
    background-color: transparent;
}
.buttonFlatS:hover {
    background-position: -430px 0px;
}
.buttonFlatS:active {
    background-position: -516px 0px;
}

.buttonL {
    width: 153px;
    height: 26px;
}
.buttonAuto {
    padding: 0 5px 0 5px;
    height: 26px;
}
.buttonAuto.arrowRight {
    padding-right: 27px;
    background-position: right -504px;
    background-image: url(../images/sprites_horizontal_breite.png), -moz-linear-gradient(top, $noColor, #ccc); /* Firefox */
    background-image: url(../images/sprites_horizontal_breite.png),
        -webkit-gradient(linear, left top, left bottom, from($noColor), to(#ccc)); /* Safari, Chrome */
    background-image: url(../images/sprites_horizontal_breite.png), -webkit-linear-gradient(top, $noColor, #ccc); /* Safari, Chrome - neue Syntax! */
    background-image: url(../images/sprites_horizontal_breite.png), -o-linear-gradient(top, $noColor, #ccc); /* Opera */
    background-image: url(../images/sprites_horizontal_breite.png), -ms-linear-gradient(top, $noColor, #ccc); /* IE */
    background-image: url(../images/sprites_horizontal_breite.png), linear-gradient(to bottom, $noColor, #ccc); /* W3C Standard */
    -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=$noColor, endColorstr=$colorMidGray)';
}
.buttonAuto.arrowLeft {
    padding-left: 27px;
    background-position: left -528px;
    background-image: url(../images/sprites_horizontal_breite.png), -moz-linear-gradient(top, $noColor, #ccc); /* Firefox */
    background-image: url(../images/sprites_horizontal_breite.png),
        -webkit-gradient(linear, left top, left bottom, from($noColor), to(#ccc)); /* Safari, Chrome */
    background-image: url(../images/sprites_horizontal_breite.png), -webkit-linear-gradient(top, $noColor, #ccc); /* Safari, Chrome - neue Syntax! */
    background-image: url(../images/sprites_horizontal_breite.png), -o-linear-gradient(top, $noColor, #ccc); /* Opera */
    background-image: url(../images/sprites_horizontal_breite.png), -ms-linear-gradient(top, $noColor, #ccc); /* IE */
    background-image: url(../images/sprites_horizontal_breite.png), linear-gradient(to bottom, $noColor, #ccc); /* W3C Standard */
    -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=$noColor, endColorstr=$colorMidGray)';
}
.buttonAuto.arrowRight:active {
    padding-right: 27px;
    background-position: right -504px;
    background-image: url(../images/sprites_horizontal_breite.png), -moz-linear-gradient(top, #ccc, $noColor); /* Firefox */
    background-image: url(../images/sprites_horizontal_breite.png),
        -webkit-gradient(linear, left top, left bottom, from(#ccc), to($noColor)); /* Safari, Chrome */
    background-image: url(../images/sprites_horizontal_breite.png), -webkit-linear-gradient(top, #ccc, $noColor); /* Safari, Chrome - neue Syntax! */
    background-image: url(../images/sprites_horizontal_breite.png), -o-linear-gradient(top, #ccc, $noColor); /* Opera */
    background-image: url(../images/sprites_horizontal_breite.png), -ms-linear-gradient(top, #ccc, $noColor); /* IE */
    background-image: url(../images/sprites_horizontal_breite.png), linear-gradient(to bottom, #ccc, $noColor); /* W3C Standard */
}
.buttonAuto.arrowLeft:active {
    padding-left: 27px;
    background-position: left -528px;
    background-image: url(../images/sprites_horizontal_breite.png), -moz-linear-gradient(top, #ccc, $noColor); /* Firefox */
    background-image: url(../images/sprites_horizontal_breite.png),
        -webkit-gradient(linear, left top, left bottom, from(#ccc), to($noColor)); /* Safari, Chrome */
    background-image: url(../images/sprites_horizontal_breite.png), -webkit-linear-gradient(top, #ccc, $noColor); /* Safari, Chrome - neue Syntax! */
    background-image: url(../images/sprites_horizontal_breite.png), -o-linear-gradient(top, #ccc, $noColor); /* Opera */
    background-image: url(../images/sprites_horizontal_breite.png), -ms-linear-gradient(top, #ccc, $noColor); /* IE */
    background-image: url(../images/sprites_horizontal_breite.png), linear-gradient(to bottom, #ccc, $noColor); /* W3C Standard */
}
.buttonAutoFlat {
    padding: 1px 5px 0 5px;
    height: 22px;
    line-height: 0px;
    font-size: 11px;
}
.button100 {
    padding: 0 5px 0 5px;
    height: 26px;
    width: 100%;
}

/* Animations */
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@mixin rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.spinnerS {
    line-height: 22px;
    color: $colorDarkGray;
    margin-top: 20px;
    text-align: left;
    width: 22px;
    white-space: nowrap;
    height: 22px;
}

.spinnerS .animate {
    @include rotating;
    border-radius: 20px;
    background-image: url(../images/svg/spinner_808080.png);
    background-position: center center;
    border: solid 1px #808080;
    width: 22px;
    height: 22px;
    float: left;
    margin-right: 5px;
    background-size: cover;
}

/* Sprites */
.sprites_main {
    background-image: url(../images/sprites_main.png);
    background-repeat: no-repeat;
}
.sprites_horizontal_breite {
    background-image: url(../images/sprites_horizontal_breite.png);
    background-repeat: no-repeat;
}

#content {
    padding-top: 110px;
    margin: 0 10px 0 10px;
    width: 1078px;
}

/* Formulare */

/* Formular Reportliste */

.reports table span.created {
    display: block;
    float: left;
    width: 130px;
}
.reports table span.created:after {
    content: ' |';
}

.reports table span.editable.name {
    display: block;
    width: 160px;
    overflow: hidden;
}

.reports table span.editable.description {
    display: block;
    /*
width:325px;
*/
    overflow: hidden;
}

/* END Formular Reportliste */

.spalte_rechts_738 form {
    margin-left: -25px;
}
.textinput {
    border: solid 1px $colorMidGray;
    font-size: 13px;
    background: $noColor;
    padding: 4px 5px 4px 5px;
    @extend .mabinsoRoundCorner;
    box-shadow: 0 0 2px $colorCorporateTurquoise;
}
#kopf .ddNavElement {
    height: 34px;
    overflow: hidden;
}
#kopf .ddNavElement span.sprites_horizontal_breite {
    background-position: left -106px;
    height: 34px;
    display: inline-block;
    line-height: 34px;
    padding-left: 5px;
}
#kopf .ddNavElement span.sprites_horizontal_breite span {
    background-position: right -72px;
    margin-left: 5px;
    padding-right: 38px;
}

fieldset {
    border: none;
    border-bottom: solid 1px $colorCorporateLightBrown;
    padding: 0 0 7px 0;
    margin: 0 0 30px 0;
    .window {
        border: none;
        border-bottom: solid 1px $colorCorporateLightBrown;
        padding: 0 0 0px 0;
        margin: 0 0 0px 24px;
        width: 400px;
    }
}

fieldset.window legend {
    width: 400px;
}

fieldset.col_30 {
    width: 230px;
    float: left;
    margin-left: 24px;
}
fieldset.col_70 {
    width: 485px;
    float: left;
    margin-left: 24px;
}
.box50_50 {
    margin-left: -25px;
}
fieldset.col_50,
div.col_50 {
    width: 357px;
    float: left;
    margin-left: 24px;
}

fieldset.noborder {
    border: none;
}

div.submit_col_50 {
    border-top: solid 1px $colorCorporateLightBrown;
    margin-top: 10px;
    padding-top: 10px;
    text-align: right;
}

fieldset.submit {
    border-bottom: 0px;
    border-top: solid 1px $colorCorporateLightBrown;
    margin-left: 24px;
    text-align: right;
    padding: 7px 7px 0 0;
}

legend {
    border-bottom: solid 1px $colorCorporateLightBrown;
    padding: 0 0px 0 10px;
    display: block;
    line-height: 30px;
    margin-bottom: 10px !important;
    font-size: 12px;
    font-weight: bold;
}
.col_50 h2 {
    border-bottom: solid 1px $colorCorporateLightBrown;
    padding: 0 0px 0 10px;
    display: block;
    line-height: 30px;
    margin-bottom: 10px !important;
    font-size: 12px;
    font-weight: bold;
}
fieldset.col_30 legend {
    width: 220px;
}
fieldset.col_70 legend {
    width: 474px;
}
fieldset.col_50 legend {
    width: 347px;
}

.input.text,
.input.checkbox {
    clear: both;
    padding: 0 0 5px 0;
    height: 24px;
    position: relative;
}

.config-interfaces {
    .input.text,
    .input.checkbox {
        height: 30px;
    }
}

.input.text label,
.input.checkbox label {
    margin: 0 0 0 10px;
    font-size: 11px;
    line-height: 24px;
    width: 100px;
    display: block;
    float: left;
}

.input.text label.file {
    width: 80px;
}

.input.text label.XS {
    width: 30px;
}
.input.text .datum {
    width: 80px;
}
#preselectdate {
}

.input.text input:focus {
    box-shadow: 0 0 2px $colorCorporateTurquoise;
    background-color: $colorCorporateLightTurquoise;
}
.input.checkbox .checkboxGroup {
    float: right;
    margin-right: 10px;
}

fieldset.col_30 .input.text input {
    width: 85px;
    margin-right: 10px;
}
fieldset.col_30 .checkboxGroup {
    width: 97px;
}
fieldset.col_50 .checkboxGroup {
    width: 219px;
}
fieldset.col_70 .checkboxGroup {
    width: 352px;
}
fieldset.col_70 .input.text input {
    width: 340px;
    margin-right: 10px;
}
fieldset.col_50 .input.text input {
    width: 212px;
    margin-right: 10px;
}
fieldset.col_50 .input.text input#mabinso_date_singleday,
fieldset.col_50 .input.text .datepicker,
fieldset.col_50 .input.text .timepicker {
    width: 78px;
}

fieldset.col_50 .input.text input[type='file'] {
    width: 130px;
    margin-left: 12px;
    float: left;
}

/* Filelist */

.filebrowser h2 {
    border-bottom: none;
    margin-bottom: 0px !important;
    font-weight: normal;
}
.filebrowser .defaultButton {
    position: absolute;
    bottom: 0px;
}
.filelist {
    border: solid 1px $colorCorporateLightBrown;
    @extend .mabinsoRoundCorner;
    height: 300px;
    overflow: auto;
}
.filelist.hasButtonsNext {
    height: 270px;
    margin-bottom: 4px;
}
.filelist ul {
    margin: 2px;
    padding: 0;
    background: $colorLightGray;
    min-height: 295px;
    list-style-type: none;
    border-top: solid 1px #ccc;
}
.filelist.hasButtonsNext ul {
    min-height: 265px;
}
.filelist ul li {
    line-height: 26px;
    border-bottom: solid 1px #ccc;
    background-color: $noColor;
    font-size: 11px;
    position: relative;
    padding: 0 0 0 5px;
}
.filelist ul li.ungerade {
    background-image: url(../images/report_ungerade_bg.png);
    background-position: left bottom;
}
.filelist ul li:hover {
    background: $colorCorporateLightTurquoise;
}
.filelist ul li .statusIconHolder {
    float: left;
    margin-right: 4px;
}
.filelist ul li .button_28 {
    margin: -1px 0 0 -2px;
    display: none;
}
.filelist ul li:hover .button_28 {
    display: block;
}

#meldungstext fieldset.col_30 .input.text input {
    width: 85px;
    margin-right: 10px;
}
#meldungstext fieldset.col_30 .checkboxGroup {
    width: 97px;
}
#meldungstext .input.positionrelative {
    border: solid 1px #f00 !important;
    background: #f00 !important;
    position: relative;
}

.numbersetMinus,
.numbersetPlus {
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    text-indent: -1000px;
    overflow: hidden;
    position: absolute;
}
.numbersetMinus {
    background-position: -344px -32px;
    left: 126px;
    top: 3px;
}
.numbersetPlus {
    background-position: -404px -32px;
    right: 13px;
    top: 3px;
}
.input.text:hover .numbersetMinus {
    background-position: -364px -32px;
}
.input.text:hover .numbersetMinus:active {
    background-position: -384px -32px;
}
.input.text:hover .numbersetPlus {
    background-position: -424px -32px;
}
.input.text:hover .numbersetPlus:active {
    background-position: -444px -32px;
}
.input.checkbox .option {
    display: inline-block;
    color: $noColor;
    padding: 5px 5px 5px 18px;
    cursor: pointer;
    @extend .mabinsoRoundCorner;
    background: $colorCorporateLightBrown;
    line-height: 16px;
    font-size: 12px;
    background-image: url(../images/punkt_weiss.png);
    background-repeat: no-repeat;
    background-position: 7px 12px;
    margin-bottom: 3px;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loginscreen .input.checkbox .option {
    background: $noColor;
    color: #000;
    font-size: 10px;
    line-height: 16px;
    padding: 2px 5px 2px 18px;
    opacity: 0.5;
    background-position: 8px -477px;
    background-image: url(../images/sprites_horizontal_breite.png);
}
.loginscreen .checkboxGroup {
    padding: 0 !important;
    margin: 0 !important;
}

.input.checkbox.listed .checkboxGroup {
    height: 150px;
    overflow: auto;
    border: solid 1px $colorCorporateLightBrown;
    @extend .mabinsoRoundCorner;
    padding: 2px;
    background: $noColor;
    margin-bottom: 4px;
}
.input.checkbox.listed .searchbar {
    position: relative;
    height: 20px;
    margin-right: 6px;
    height: 24px;
    margin-top: -24px;
}
.input.checkbox.listed .searchbar input {
    visibility: visible !important;
    border: solid 1px $colorCorporateLightBrown;
    @extend .mabinsoRoundCorner;
    width: 100%;
    height: 20px;
    text-indent: 5px;
}
.input.checkbox.listed .searchbar .opener {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    overflow: hidden;
    right: 10px;
    top: 21px;
    background-position: left -33px;
}
.input.checkbox.listed .searchbar .opener.active {
    background-position: -16px -33px;
}

.input.checkbox.listed .option {
    display: block;
    margin-right: 4px;
}
.input.checkbox .option.small {
    padding: 0 5px 0 18px;
    cursor: pointer;
    line-height: 18px;
    font-size: 11px;
    margin-bottom: 3px;
    background-position: 8px -234px;
}
.input.checkbox .option.active {
    background-repeat: no-repeat;
    background-image: url(../images/haken_weiss.png);
    background-position: 6px 10px;
    background-color: $colorCorporateBrown;
}

.input.checkbox .checkboxRW {
    .option.active {
        background-image: none;
        padding-left: 4px;
        .optBeschriftung {
            &.optRead:before {
                content: 'R';
                padding-right: 10px;
            }
            &.optWrite:before {
                content: 'W';
                padding-right: 10px;
            }
        }
    }
}

.loginscreen .input.checkbox .option.active {
    background-repeat: no-repeat;
    background-position: 7px 6px;
    background-color: $colorCorporateTurquoise;
    opacity: 1;
    color: $noColor;
}

/* Hier starten die Stile für die Benutzerverwaltung */

.spalte_links_280 {
    width: 280px;
    font-size: 13px;
    margin-top: 30px;
    color: #333;
    border-right: dotted 1px #ccc;
    background-image: url(../images/spalte_links_280.png);
    background-position: right top;
    background-repeat: no-repeat;
    min-height: 540px;
    float: left;
    -webkit-transition: width 0.2s;
    transition: width 0.2s;

    text-overflow: ellipsis;
    ul {
        list-style-type: none;
        position: relative;
        text-overflow: ellipsis;
        li {
            line-height: 20px;
            padding: 15px 0 15px 15px;
            cursor: pointer;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
.spalte_links_280 .rolle {
    color: $colorDarkGray;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 85%;
}
.spalte_links_280 .name {
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 85%;
}
.spalte_links_280 .active .rolle {
    color: #333;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 85%;
}
#aktivzeiger {
    background-image: none;
    width: 280px;
    height: 72px;
    background-position: left -349px;
    position: absolute;
    top: 0px;
    border: solid 1px #6cbbd8;
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
    background: $colorCorporateLightTurquoise; /* Old browsers */
    @include mabinsoGradientStandard($colorCorporateLightTurquoise, #c8e4ef);
    .spitze {
        border-color: transparent transparent transparent #6cbbd8;
        border-style: solid;
        border-width: 37px;
        position: absolute;
        right: -75px;
        top: -1px;
        .spitzeinner {
            border-color: transparent transparent transparent #c8e4ef;
            border-style: solid;
            border-width: 36px;
            left: -38px;
            position: absolute;
            top: -36px;
        }
    }
}

.spalte_100 {
    margin-top: 30px;
}
.spalte_rechts_738 {
    width: 738px;
    float: right;
    margin-top: 30px;
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
    h1 {
        font-size: 13px;
        line-height: 30px;
        color: #333;
        padding-left: 10px;
    }
    .einleitung {
        width: 350px;
        border: solid 1px #ccc;
        margin: auto;
        @extend .mabinsoRoundCorner;
        padding: 20px;
        font-size: 13px;
        color: $colorDarkGray;
        line-height: 24px;
        background: $colorLightGray;
        margin-top: 150px;
        .infoIcon50 {
            float: left;
        }
        p {
            margin-left: 70px;
            border-left: solid 1px #ccc;
            padding-left: 20px;
            min-height: 150px;
        }
    }
    /* .reiter .reiteritem.active.active{ text-decoration: none;}*/
}

.col_changable_width {
    &.minimized {
        #aktivzeiger {
            width: 80px;
        }
        .spalte_links_280 {
            width: 80px;
        }
        .spalte_rechts_738 {
            width: 938px;
        }
    }
}

.reiter {
    border-bottom: solid 1px $colorCorporateLightBrown;
    margin-bottom: 20px;
    white-space: nowrap;
    h1 {
        font-size: 13px;
        line-height: 30px;
        color: #333;
        padding-left: 10px;
    }
    .reiteritem {
        float: right;
        font-size: 13px;
        line-height: 30px;
        padding: 0 20px 0 20px;
        border-left: solid 1px $colorCorporateLightBrown;
        border-bottom: solid 1px $colorCorporateLightBrown;
        color: $colorCorporateLightBrown;
        cursor: pointer;
        &:last-of-type {
            border-left: none;
            &.active {
                border-left: solid 1px $colorCorporateLightBrown !important;
            }
        }
        &.active {
            border-bottom: solid 1px $noColor;
            color: #333;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

/* Icons */
.infoIcon50 {
    width: 50px;
    height: 50px;
    background-position: -770px top;
}

/* Loginscreen */
#bgLoginscreen {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
}

.loginscreen {
    margin: auto;
    width: 402px;
    padding-top: 300px;
    background-image: url(../images/logo_login.png);
    background-repeat: no-repeat;
    background-position: center 240px;
    label {
        color: $noColor;
        font-size: 13px !important;
    }
    .input {
        &.text {
            input {
                border: none;
                width: 150px !important;
                margin-bottom: 10px;
                &.remember {
                    width: 10px !important;
                }
            }
        }
    }
    fieldset {
        border-top: dotted 1px $noColor !important;
        border-bottom: dotted 1px $noColor !important;
        padding: 25px 60px 20px 60px;
        margin-bottom: 5px;
    }
    p {
        version {
            margin-bottom: 20px;
            text-align: right;
            color: grey;
        }
    }
}
.login {
    border: none;
    @extend .mabinsoRoundCorner;
    color: $noColor;
    background-color: transparent;
    border: solid 1px $noColor;
    background-image: url(../images/sprites_horizontal_breite.png);
    background-repeat: no-repeat;
    background-position: left -258px;
    padding: 3px 20px 3px 20px;
    font-size: 13px;
    margin-left: 182px;
    float: right;
}
.login:hover {
    box-shadow: 0 0 2px $noColor;
}
.login:active,
.login:focus {
    background-position: left -282px !important;
    box-shadow: none;
}

/* Error-Hinweise und Tooltipps */

.tooltip {
    position: absolute;
}
.tooltip .oben {
    position: absolute;
    color: $noColor;
    top: 0px;
    right: 2px;
    text-align: right;
}
.tooltip .mitte {
    @extend .mabinsoRoundCorner;
    color: $noColor;
    padding: 7px 9px 9px 9px;
    text-align: left;
    background: rgba(0, 53, 103, 0.9);
    @include mabinsoGradientStandard(rgba(0, 53, 103, 0.9), rgba(10, 26, 59, 0.9));
    font-size: 11px;
    line-height: 18px;
}
.tooltip.errortip .mitte {
    background: rgba(210, 0, 0, 0.9);
    @include mabinsoGradientStandard(rgba(255, 57, 57, 0.9), rgba(210, 0, 0, 0.9));
}
.tooltip .unten {
    background-image: url(../images/sprites_horizontal_breite.png);
    background-repeat: no-repeat;
    background-position: center -387px;
    height: 6px;
    opacity: 0.9;
}
.tooltip.errortip .unten {
    background-position: center -381px;
    height: 6px;
}

/* Allgemeine Objekte */

iframe.iframe-download {
    display: none;
}

/* Farben für text */
.fehler,
.rot {
    color: $colorError;
}

/*Progressbar */
#progress {
    margin-top: 20px;
    width: 100%;
    height: 20px;
}
#progress .progress-bar {
    background: url('../images/progressbar.gif') !important;
    width: 0%;
    height: 100%;
}

.show-buttons .sep:last-of-type{ display:none }
