/* Standardtabelle mit wechselnden Zeilen und Beschriftung oben */

.standardtable{
	border-collapse: collapse;
	width:100%;
	text-align:left;
}
.standardtable .description td{
	padding:0 10px 0 10px;
	background: #ac9c79;	
	font-size:11px;
	height:20px;
	line-height:20px;
	border-right:solid 1px #7d6f53;
	border-bottom: solid 1px #7d6f53;
	color:#fff;
}
.standardtable td.displayname{
	width:80%;
}
.standardtable td{
	border-bottom: solid 1px #ccc;
	background-image:url(../images/trenner_td.png);
	background-repeat: no-repeat;
	background-position: right bottom;	
	font-size:13px;
	line-height:150%;
	padding:5px 10px 5px 10px;
	vertical-align:top;
}

.standardtable tr.ungerade{
	background-image:url(../images/report_ungerade_bg.png);
	background-position: left bottom;
	background-repeat:repeat-x;
	background-color:#fff;
}
.standardtable tr.gerade{
	background:#fff;
}
.standardtable tr:hover,.standardtable tr.active{
	background:#e2f1f7;
}
.standardtable tr td:last-child{
	background-image:none;
	border-right:none;
}
.standardtable tr.fehler td{
	background:#ffcccc;
	border-bottom:solid 1px #d30000;
}
.standardtable tr.warnung td{
	background:#ffffcc;
	border-bottom:solid 1px #ffcc00;
}
.standardtable tr.fehler:hover td{
	background:#d30000;
	border-bottom:solid 1px #d30000;
	color:#fff;
}
.standardtable .statusicon{
	margin-right:10px;
}
.standardtable tr.fehler:hover .statusicon{
	background-position:-82px -511px;
}
.standardtable tr.warnung:hover .statusicon{
	background-position:-98px -511px;
}
.standardtable tr.warnung:hover td{
	background:#ffcc00;
	border-bottom:solid 1px #ffcc00;
}
.standardtable .buttons{
	padding:0px;
}
.standardtable .actions{
	visibility:hidden;
}
.standardtable tr:hover .actions{
	visibility:	visible;
}
.scrollingloader{
	font-size:12px;
	width:99%!important;;
	float:left;
	margin-left:0px;
	margin-top:5px!important;;
}
.scrollingloader .animated{
	float:left!important;
}