.ui-dialog{
	position:relative!important;
	.ui-dialog-titlebar-close{
		background:$colorCorporateLightBlue!important;
		height:22px!important;
		width:22px!important;
		margin-top:-2px!important;
		margin-right:-2px!important;
		@extend .mabinsoRoundCorner;
		background-image:url('../images/svg/close_ffffff.svg')!important;
		background-repeat:no-repeat!important;
		background-position: center center!important;
		&:hover{
			  background-color:$colorCorporateBlue!important;
			  background-image:url('../images/svg/close_ffffff.svg')!important;
			  background-repeat:no-repeat!important;
			  background-position: center center!important;
		}
		&:active{
			background-color:$noColor!important;
			background-image:url('../images/svg/close_0a193a.svg')!important;
			background-repeat:no-repeat!important;
			background-position: center center!important;
			border:solid 1px $colorCorporateBlue!important;
		}
	}
}

.ui-corner-all{
	border-radius: 0px!important;
}
	
.ui-datepicker{
	font-family:"Lucida Grande","Lucida Sans Unicode","Lucida Sans","Tahoma"!important;
	padding:0px!important;
	border:solid 1px #fff!important;
	box-shadow: 0 0 2px #aaa;
	.ui-widget-header {
    	background: #ac9c79 none repeat scroll 0 0;
		border: medium none;
		color: #ffffff;
		font-weight: bold;
	}
	thead{
		tr{
			background-color: #7d6f53;
			color: #fff;
		}
	}
	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default,
	.ui-button,
	html .ui-button.ui-state-disabled:hover,
	html .ui-button.ui-state-disabled:active{
		@extend .standardButton;
		&.ui-state-highlight{
			background:$colorCorporateLightBrown!important;
			border:solid 1px $colorCorporateLightBrown!important;
			color:#fff;
		}
	}
	.ui-datepicker-next,
	.ui-datepicker-next:hover{
		border-color:$colorCorporateLightBrown;
		background: $colorCorporateLightBrown;

	}
	.ui-datepicker-prev,
	.ui-datepicker-prev:hover{
		border-color:$colorCorporateLightBrown;
		background: $colorCorporateLightBrown;

	}
	/* states and images */
	.ui-icon {
		width: 16px;
		height: 16px;
	}
	.ui-icon,
	.ui-widget-content .ui-icon {
		background-image: url("../images/jquery-ui/ui-icons_ffffff_256x240.png")!important;;
	}
	.ui-widget-header .ui-icon {
		background-image: url("../images/jquery-ui/ui-icons_ffffff_256x240.png")!important;;
	}
	.ui-state-hover .ui-icon,
	.ui-state-focus .ui-icon,
	.ui-button:hover .ui-icon,
	.ui-button:focus .ui-icon {
		background-image: url("../images/jquery-ui/ui-icons_ffffff_256x240.png")!important;;
	}
	.ui-state-active .ui-icon,
	.ui-button:active .ui-icon {
		background-image: url("../images/jquery-ui/ui-icons_ffffff_256x240.png")!important;;
	}
	.ui-state-highlight .ui-icon,
	.ui-button .ui-state-highlight.ui-icon {
		background-image: url("../images/jquery-ui/ui-icons_ffffff_256x240.png")!important;;
	}
	.ui-state-error .ui-icon,
	.ui-state-error-text .ui-icon {
		background-image: url("../images/jquery-ui/ui-icons_ffffff_256x240.png")!important;;
	}
	.ui-button .ui-icon {
		background-image: url("../images/jquery-ui/ui-icons_ffffff_256x240.png");
	}
	
	.ui-icon-circle-triangle-w { background-position: -80px -192px!important; }
	.ui-icon-circle-triangle-e { background-position: -48px -192px!important; }
}


/* css for timepicker */
.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
.ui-timepicker-div dl { text-align: left; }
.ui-timepicker-div dl dt { float: left; clear:left; padding: 0 0 0 5px; }
.ui-timepicker-div dl dd { margin: 0 10px 10px 40%; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }
.ui-timepicker-div .ui_tpicker_unit_hide{ display: none; }

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input { background: none; color: inherit; border: none; outline: none; border-bottom: solid 1px #555; width: 95%; }
.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input:focus { border-bottom-color: #aaa; }

.ui-timepicker-rtl{ direction: rtl; }
.ui-timepicker-rtl dl { text-align: right; padding: 0 5px 0 0; }
.ui-timepicker-rtl dl dt{ float: right; clear: right; }
.ui-timepicker-rtl dl dd { margin: 0 40% 10px 10px; }

/* Shortened version style */
.ui-timepicker-div.ui-timepicker-oneLine { padding-right: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time, 
.ui-timepicker-div.ui-timepicker-oneLine dt { display: none; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label { display: block; padding-top: 2px; }
.ui-timepicker-div.ui-timepicker-oneLine dl { text-align: right; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd, 
.ui-timepicker-div.ui-timepicker-oneLine dl dd > div { display:inline-block; margin:0; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before { content:':'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_millisec:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_microsec:before { content:'.'; display:inline-block; }
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide,
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide:before{ display: none; }