/* Plusbutton-Schildchen in Listen */
.plusbuttonList_left{
	background-image:url(../images/sprites_main.png);
	background-repeat: no-repeat;
	background-position: -474px -32px;
	height:19px;
	width:27px;
	border:none;
	overflow:hidden;
	text-indent:-1000px;
	background-color:transparent;
	&:hover{
  	background-position: -501px -32px;
	}
	&:focus, &:active{
  	background-position: -528px -32px;
	}
}
.plusbuttonList_right{
	background-image:url(../images/sprites_main.png);
	background-repeat: no-repeat;
	background-position: -845px -32px;
	height:19px;
	width:27px;
	border:none;
	overflow:hidden;
	text-indent:-1000px;
	background-color:transparent;
	&:hover{
  	background-position: -872px -32px;
	}
	&:focus, &:active{
  	background-position: -899px -32px;
	}
}

// Standard-Button
.standardButtonBackground{
	@include mabinsoGradientStandard($noColor, $colorMidGray);
}
.standardButtonBackgroundActive{
	@include mabinsoGradientStandard($colorMidGray, $noColor);
}
.standardButton{
	@extend .standardButtonBackground;
	@extend .mabinsoRoundCorner;
	border: 1px solid #333!important;
	box-shadow: 0 0 2px #aaa;
	color: #333;
	text-align: center;
	&:hover{
		box-shadow: none;
	}
	&:active{
		box-shadow: none;
		@extend .standardButtonBackgroundActive;
	}
	&:disabled{
  	opacity: 0.5;
	}
}