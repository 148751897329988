fieldset {
    border: none;
    border-bottom: solid 1px #ac9c79;
    padding: 0 0 7px 0;
    margin: 0 0 30px 0;
}
fieldset .window {
    border: none;
    border-bottom: solid 1px #ac9c79;
    padding: 0 0 0px 0;
    margin: 0 0 0px 24px;
    width: 400px;
}

fieldset.window legend {
    width: 400px;
}

fieldset.col_30 {
    width: 230px;
    float: left;
    margin-left: 24px;
}
fieldset.col_70 {
    width: 485px;
    float: left;
    margin-left: 24px;
}
.box50_50 {
    margin-left: -25px;
}
fieldset.col_50,
div.col_50 {
    width: 357px;
    float: left;
    margin-left: 24px;
}

fieldset.noborder {
    border: none;
}

div.submit_col_50 {
    border-top: solid 1px #ac9c79;
    margin-top: 10px;
    padding-top: 10px;
    text-align: right;
}

fieldset.submit {
    border-bottom: 0px;
    border-top: solid 1px #ac9c79;
    margin-left: 24px;
    text-align: right;
    padding: 7px 7px 0 0;
}

legend {
    border-bottom: solid 1px #ac9c79;
    padding: 0 0px 0 10px;
    display: block;
    line-height: 30px;
    margin-bottom: 10px !important;
    font-size: 12px;
    font-weight: bold;
}
.col_50 h2 {
    border-bottom: solid 1px #ac9c79;
    padding: 0 0px 0 10px;
    display: block;
    line-height: 30px;
    margin-bottom: 10px !important;
    font-size: 12px;
    font-weight: bold;
}
fieldset.col_30 legend {
    width: 220px;
}
fieldset.col_70 legend {
    width: 474px;
}
fieldset.col_50 legend {
    width: 347px;
}

.input.text,
.input.checkbox {
    clear: both;
    padding: 0 0px 5px 0;
    height: 24px;
    position: relative;
}

.input.tableselect {
    clear: both;
    padding: 0 0px 5px 0;
    position: relative;
}

.config-interfaces .input.text,
.input.checkbox {
    height: 30px;
}

.input.tableselect label,
.input.text label,
.input.checkbox label {
    margin: 0 0 0 10px;
    font-size: 11px;
    line-height: 24px;
    width: 100px;
    display: block;
    float: left;
}
.input.tableselect {
    .tableSelectHolder {
        width: calc(100% - 122px);
        float: right;
        margin-left: 3px;
    }
}

.input.text label.file {
    width: 80px;
}

.input.text label.XS {
    width: 30px;
}
.input.text input {
    border: solid 1px #cccccc;
    font-size: 13px;
    background: #fff;
    padding: 0 5px 0 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    height: 24px;
    float: right;
    color: #333;
}
.input.text .datum {
    width: 80px;
}
#preselectdate {
}

.input.text input:focus {
    box-shadow: 0 0 2px #00abd3;
    background-color: #e2f1f7;
}
.input.checkbox .checkboxGroup {
    float: right;
    margin-right: 10px;
}

fieldset.col_30 .input.text input {
    width: 85px;
    margin-right: 10px;
}
fieldset.col_30 .checkboxGroup {
    width: 97px;
}
fieldset.col_50 .checkboxGroup {
    width: 219px;
}
fieldset.col_70 .checkboxGroup {
    width: 352px;
}
fieldset.col_70 .input.text input {
    width: 340px;
    margin-right: 10px;
}
fieldset.col_50 .input.text input {
    width: 212px;
    margin-right: 10px;
}
fieldset.col_50 .input.text input#mabinso_date_singleday,
fieldset.col_50 .input.text .datepicker,
fieldset.col_50 .input.text .timepicker {
    width: 78px;
}

fieldset.col_50 .input.text input[type='file'] {
    width: 130px;
    margin-left: 12px;
    float: left;
}

fieldset.col_70 .checkboxGroup {
    width: 352px;
}
fieldset.col_70 .input.text input {
    width: 340px;
    margin-right: 10px;
}
fieldset.col_50 .input.text input {
    width: 212px;
    margin-right: 10px;
}

.numbersetMinus,
.numbersetPlus {
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    text-indent: -1000px;
    overflow: hidden;
    position: absolute;
}
.numbersetMinus {
    background-position: -344px -32px;
    left: 126px;
    top: 3px;
}
.numbersetPlus {
    background-position: -404px -32px;
    right: 13px;
    top: 3px;
}
.input.text:hover .numbersetMinus {
    background-position: -364px -32px;
}
.input.text:hover .numbersetMinus:active {
    background-position: -384px -32px;
}
.input.text:hover .numbersetPlus {
    background-position: -424px -32px;
}
.input.text:hover .numbersetPlus:active {
    background-position: -444px -32px;
}
.input.checkbox .option {
    display: inline-block;
    color: #fff;
    padding: 5px 5px 5px 18px;
    cursor: pointer;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background: #ac9c79;
    line-height: 16px;
    font-size: 12px;
    background-image: url(../images/punkt_weiss.png);
    background-repeat: no-repeat;
    background-position: 7px 12px;
    margin-bottom: 3px;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input.checkbox.listed .checkboxGroup {
    height: 150px;
    overflow: auto;
    border: solid 1px #ac9c79;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    padding: 2px;
    background: #fff;
    margin-bottom: 4px;
}
.input.checkbox.listed .searchbar {
    position: relative;
    height: 20px;
    margin-right: 6px;
    height: 24px;
    margin-top: -24px;
}
.input.checkbox.listed .searchbar input {
    visibility: visible !important;
    border: solid 1px #ac9c79;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    width: 100%;
    height: 20px;
    text-indent: 5px;
}
.input.checkbox.listed .searchbar .opener {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    overflow: hidden;
    right: 10px;
    top: 21px;
    background-position: left -33px;
}
.input.checkbox.listed .searchbar .opener.active {
    background-position: -16px -33px;
}

.input.checkbox.listed .option {
    display: block;
    margin-right: 4px;
}
.input.checkbox .option.small {
    padding: 0 5px 0 18px;
    cursor: pointer;
    line-height: 18px;
    font-size: 11px;
    margin-bottom: 3px;
    background-position: 8px -234px;
}
.input.checkbox .option.active {
    background-repeat: no-repeat;
    background-image: url(../images/haken_weiss.png);
    background-position: 6px 10px;
    background-color: #7d6f53;
}

.input.checkbox .checkboxRW {
    .option.active {
        background-image: none;
        padding-left: 4px;
        .optBeschriftung {
            &.optRead:before {
                content: 'R';
                padding-right: 10px;
            }
            &.optWrite:before {
                content: 'W';
                padding-right: 10px;
            }
        }
    }
}

.input.checkbox .option.active.small {
    background-position: 6px -205px;
}
.input.checkbox input {
    visibility: hidden;
    position: absolute;
}
