/* Reportzeilen */

#reportzeilen {
    display: block !important;
    padding: 50px 0 0 0;
    font-size: 13px;
    span {
        &.numbers3 {
            display: block;
            float: left;
            width: 25px;
            text-align: center;
            padding-right: 5px;
        }
    }
    &.selectmode {
        .buttonsammlung_reportzeilen {
            display: block;
            .listSelector {
                position: absolute;
                left: 233px;
            }
        }
        .listSelector {
            display: block;
        }
        .report {
            .listSelector {
                display: block;
            }

            &:not(.fail) {
                &:hover {
                    .reportactions {
                        .show-buttons {
                            display: inline !important;
                        }
                    }
                }
            }
        }
        .reporttyp {
            .typbeschriftung_inner {
                .category-name {
                    width: 58% !important;
                }
            }
            .listSelector {
                display: block;
            }
        }
        .reportactions {
            display: block;
            .show-buttons {
                display: none;
            }
            .button_28 {
                display: none;
            }
            .listSelector {
                display: inline;
            }
        }
        .buttonsammlung_reportzeilen {
            .input {
                display: none;
            }
        }
    }
}

.addReportCategory {
    position: fixed;
    top: 155px;
    /* left:-17px; */
    margin-left: -27px;
}

.reporttyp {
    min-height: 29px;
    line-height: 28px;
    margin-top: 10px;
    &:hover {
        .typbeschriftung_inner {
            .category-name {
                width: 58% !important;
            }
            .listSelector {
                display: block !important;
            }
        }
    }
    .typbeschriftung {
        width: 1078px;
        height: 1px;
        color: #fff;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        border-top: solid 1px $colorCorporateLightBrown;
        &.true,
        &.some {
            border-top: solid 1px $colorCorporateTurquoise;
            .typbeschriftung_inner {
                background-image: url($bg_typbeschriftung_01aad3);
            }
            .opentype {
                background-color: $colorCorporateTurquoise;
                &:hover {
                    background-color: $colorCorporateTurquoise;
                }
            }
        }
        .opentype {
            width: 18px;
            height: 18px;
            border: none;
            background: $colorCorporateBrown;
            position: absolute;
            left: 6px;
            top: 5px;
            border: solid 1px #fff;
            border-radius: 3px;
            background-image: url($plus_ffffff);
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
            &:hover {
                background-color: $colorCorporateLightBrown;
            }
        }
        &.active {
            .opentype {
                background-image: url($minus_ffffff);
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        &.scrollfix {
            position: fixed;
            top: 170px;
        }
        .typbeschriftung_inner {
            width: 159px;
            height: 29px;
            background-image: url($bg_typebeschriftung);
            background-position: top right;
            padding: 0 0 0 30px;
            .category-name {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 80%;
            }
        }
        .listSelector {
            float: right;
            margin-right: 25px;
            display: none;
        }
    }
}

.typinfo_outer {
    display: inline-block;
    margin-left: 190px;
}
.typinfo {
    display: inline-block;
    color: #7e6f53;
    border-right: dotted 1px $colorCorporateLightBrown;
    padding: 0 8px 0 5px;
    height: 20px;
}
.typinfo {
    border: none;
}
.zahlenkasten {
    display: inline-block;
    width: autop;
    height: 20px;
    background-position: 0px -52px;
    color: #fff;
    padding: 0 0 0 3px;
    line-height: 20px;
    .zahlenkasten_inner {
        display: inline-block;
        background-position: right -32px;
        width: 35px;
        height: 20px;
        padding: 0 3px 0 0;
        line-height: 20px;
        text-align: center;
    }
}
.reportvorlagensammlung {
    display: none;
    width: 912px;
    float: right;
}
.inhalt_reporttyp {
    display: inline-block;
    width: 912px;
}
.reportvorlage {
    width: 912px;
    height: auto;
    margin: 10px 0 10px 0;
    display: inline-block;
    color: #fff;
    line-height: 30px;
    &:not(.hasReportsSaved) {
        .buttonsammlung_reportzeilen {
            .listSelector {
                display: none !important;
            }
        }
    }
    .reporttitel {
        background-image: url($bg_reportssaved_7d6f53);
        background-position: left top;
        height: 30px;
        position: relative;
        padding-left: 57px;
        &.true {
            background-image: url($bg_reportssaved_01aad3);
            &.active {
                background-image: url($bg_reportssaved_01aad3);
            }
        }
        &.some {
            background-image: url($bg_reportssaved_01aad3);
            &.active {
                background-image: url($bg_reportssaved_01aad3);
            }
        }
        &.hasReportsSaved {
            &.active {
                .statusBackground {
                }
                .openvorlage {
                    background-image: url($minus_ffffff);
                }
            }
            .statusBackground {
                background: #f00;
                height: 30px;
                display: none;
                padding-left: 57px;
                position: relative;
            }
            .openvorlage {
                width: 18px;
                height: 18px;
                border: none;
                background: transparent;
                position: absolute;
                left: 33px;
                top: 6px;
                border: solid 1px #fff;
                border-radius: 3px;
                background-image: url($plus_ffffff);
                background-repeat: no-repeat;
                background-position: center center;
                cursor: pointer;
                opacity: 1;
            }
        }
        .statusBackground {
            width: 100%;
            height: 30px;
            display: block;
            padding-left: 57px;
            position: relative;
        }
        .openvorlage {
            width: 18px;
            height: 18px;
            border: none;
            background: transparent;
            position: absolute;
            left: 33px;
            top: 6px;
            border: solid 1px #fff;
            border-radius: 3px;
            background-image: url($plus_ffffff);
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
            opacity: 0.3;
        }

        &.inaction {
            background-image: url(../images/svg/bg_reportssaved_inaction.svg);
        }
        &.deleted {
            opacity: 0.5;
        }
    }
}
.typbeschriftung {
    .waitreport {
        background-image: url(../images/spinnerS_white.png);
        background-position: left top;
        background-repeat: repeat-x;
        background-color: $colorCorporateLightBrown;
        margin-top: 5px;
        margin-left: -24px;
        height: 18px;
        width: 18px;
        float: left;
    }
}
.reporttitel .waitreport {
    background-image: url(../images/spinnerS_white.png);
    background-position: left bottom;
    background-repeat: repeat-x;
    background-color: $colorCorporateBrown;
    margin-top: 5px;
    margin-left: -24px;
    height: 19px;
    width: 18px;
    float: left;
}
.reporttitel:hover .waitreport {
    background-color: #0a193a;
}
.reporttitel.active {
    background-image: url($bg_reportssaved_7d6f53);
    background-position: left top;
}
.reportvorlage {
    &:hover {
        .reporttitel {
            background-image: url($bg_reportssaved_0a193a);
            background-position: left top;
            &.true,
            &.some {
                background-image: url($bg_reportssaved_01aad3) !important;
            }
            &.active {
                background-image: url($bg_reportssaved_0a193a);
                background-position: left top;
            }
        }
    }
    &.deleted {
        .buttonsammlung_reportzeilen {
            // display:none!important;;
            .input {
                // display:none!important;;
            }
            .listSelector {
                display: none !important;
            }
        }
    }
}
.listSelector {
    background-color: transparent;
    display: inline-block;
    overflow: hidden;
    text-indent: -1000px;
    width: 28px;
    height: 28px;
    border: none;
    &.false {
        background-image: url($select_line_none_ffffff);
        background-repeat: no-repeat;
        background-position: center center;
        &:hover {
            background-image: url($select_line_none_hover_ffffff);
        }
    }
    &.true {
        background-image: url($select_line_selected_ffffff);
        background-repeat: no-repeat;
        background-position: center center;
        &:hover {
            background-image: url($select_line_selected_ffffff);
        }
    }
    &.some {
        background-image: url($select_line_some_ffffff);
        background-repeat: no-repeat;
        background-position: center center;
        &:hover {
            background-image: url($select_line_none_hover_ffffff);
        }
    }
}

.reportvorlage {
    &:hover {
        .buttonsammlung_reportzeilen {
            .input {
                display: block;
            }
            .listSelector {
                display: block;
            }
        }
    }

    .buttonsammlung_reportzeilen {
        width: 260px;
        position: absolute;
        margin-left: 650px;
        margin-top: 2px;
        .input {
            display: none;
            z-index: 1;
        }
        .listSelector {
            position: absolute;
            top: -1px;
            left: 95px;
            z-index: 10;
            display: none;
        }
    }
}

.reports {
    display: none;
    width: 912px;
}
.reports table {
    margin: 0px 0px 0px 0px;
    padding: 0px;
    border-collapse: collapse;
    table-layout: fixed;
}

.reports table.edit {
    margin: 0px 0px 30px 0px;
}

tr.report.search-hide,
tr.reportbeschriftung.search-hide {
    display: none !important;
}
.report td {
    border-bottom: solid 1px #ccc;
    padding-left: 10px;
    background-image: url(../images/trenner_td.png);
    background-repeat: no-repeat;
    background-position: right bottom;
}

td.last {
    width: 221px;
}

.report td.last {
    background: none;
    width: 200px;
}
td.reportname {
    width: 135px;
}

.report td.reportname {
    color: #333;
    display: table-cell;
    white-space: nowrap;
}
span.ellipsis {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}
span.shaker {
    overflow: hidden;
    display: block;
    position: relative;
}
.report span.shaker {
    width: 480px;
}
span.shakerInner {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: auto !important;
}

div.reports tr.report.new {
    font-weight: bold;
}

td.description {
    width: 496px;
}

.report {
    width: 912px;
    color: $colorDarkGray;
    &.ungerade {
        background-image: url(../images/report_ungerade_bg.png);
        background-position: left bottom;
    }
    &.gerade {
        background: #fff;
    }
    td {
        &.reportname {
            input {
                width: 150px;
            }
            span {
                &.ellipsis {
                    width: 300px;
                }
            }
        }
        &.description {
            white-space: nowrap;
            span {
                &.ellipsis {
                    width: 331px;
                }
            }
            input {
                width: 300px;
            }
        }
    }
    &.selected {
        background: $colorCorporateLightTurquoise;
        .reportactions {
            display: inline;
            .show-buttons {
                display: none;
            }
            .button_28 {
                display: none;
            }
            .listSelector {
                display: inline;
            }
        }
    }
    .reportactions {
        display: none;
        &.hidden {
            display: none !important;
        }
        .button_28 {
            margin-top: 1px;
        }
        .listSelector {
            float: right;
            &.false {
                background-image: url($select_line_none_01aad3);
                background-repeat: no-repeat;
                background-position: center center;
                &:hover {
                    background-image: url($select_line_none_hover_01aad3);
                }
            }
            &.true {
                background-image: url($select_line_selected_01aad3);
                background-repeat: no-repeat;
                background-position: center center;
                &:hover {
                    background-image: url($select_line_selected_01aad3);
                }
            }
        }
    }
    &.hover {
        &:hover {
            .reportactions {
                display: inline;
            }
        }
    }
    &.showHover {
        .reportactions {
            display: inline;
            .show-buttons {
                display: none;
            }
        }
    }
}

.statusspalte {
    width: 50px;
}
.status {
    text-align: center;
    padding: 0 !important;
    min-width: 60px;
}

.report.hover.ungerade:hover,
.report.hover.gerade:hover,
.report.showHover.gerade,
.report.showHover.ungerade {
    background: $colorCorporateLightTurquoise;
    color: #333;
}
.report span.editable.simple.hidden {
    display: none;
}

td.status span.hidden {
    display: none;
}
.status_fehler,
.error .statusicon {
    background-image: url(../images/sprites_main.png);
    background-repeat: no-repeat;
    background-position: left -511px;
    width: 16px;
    height: 15px;
    overflow: hidden;
    text-indent: -1000px;
    display: inline-block;
    position: relative;
    margin-bottom: -3px;
}
.status_warnung,
.warn .statusicon {
    background-image: url(../images/sprites_main.png);
    background-repeat: no-repeat;
    background-position: -66px -511px;
}
.status_info,
.info .statusicon {
    background-image: url(../images/sprites_main.png);
    background-repeat: no-repeat;
    background-position: -114px -511px;
}
.status_ok {
    background-position: -16px -511px;
    width: 16px;
    height: 15px;
    overflow: hidden;
    text-indent: -1000px;
    display: inline-block;
}
.status_ok_empty {
    background-position: -50px -511px;
    width: 16px;
    height: 15px;
    overflow: hidden;
    text-indent: -1000px;
    display: inline-block;
}
.status_inaktion {
    background-position: -32px -511px;
    width: 16px;
    height: 15px;
    overflow: hidden;
    text-indent: -1000px;
    display: inline-block;
}
.statusicon {
    width: 16px;
    height: 15px;
    overflow: hidden;
    text-indent: -1000px;
    display: inline-block;
    position: relative;
    margin-bottom: -3px;
}
.button_28 {
    display: inline-block;
    width: 28px;
    height: 28px;
    text-indent: -1000px;
    overflow: hidden;
}
.button_18 {
    display: inline-block;
    width: 18px;
    height: 18px;
    text-indent: -1000px;
    overflow: hidden;
    &.loeschen {
        background-position: -316px -349px;
        &:hover {
            background-position: -334px -349px;
        }
        &:active {
            background-position: -352px -349px;
        }
    }
}
.spalte_links_280 .button_18 {
    position: absolute;
    margin-top: 1px;
    margin-left: 5px;
    display: none;
}
.spalte_links_280 .active:hover .button_18 {
    display: inline-block;
}
.button_28.loeschen {
    background-position: -344px top;
    float: right;
}
.button_28.loeschen:hover {
    background-position: -372px top;
}
.button_28.loeschen:active {
    background-position: -400px top;
}

.button_28.abbrechen {
    background-position: -120px top;
    float: right;
}
.button_28.abbrechen:hover {
    background-position: -148px top;
}
.button_28.abbrechen:active {
    background-position: -176px top;
}

.button_28.ok {
    background-position: -232px -28px;
    float: right;
}
.button_28.ok:hover {
    background-position: -260px -28px;
}
.button_28.ok:active {
    background-position: -288px -28px;
}
.button_28.pfeillinks {
    background-position: -456px top;
    float: right;
}
.button_28.pfeillinks:hover {
    background-position: -484px top;
}
.button_28.pfeillinks:active {
    background-position: -512px top;
}
.button_28.uhr {
    background-position: -680px top;
    float: right;
}
.button_28.uhr:hover {
    background-position: -708px top;
}
.button_28.uhr:active {
    background-position: -736px top;
}

.button_28.bearbeiten {
    background-position: -120px -28px;
    float: right;
}
.button_28.bearbeiten:hover {
    background-position: -148px -28px;
}
.button_28.bearbeiten:active {
    background-position: -176px -28px;
}
.button_28.bearbeiten.active {
    background-position: -232px -28px;
}
.button_28.bearbeiten.active:hover {
    background-position: -260px -28px;
}
.button_28.bearbeiten.active:active {
    background-position: -288px -28px;
}

.reportbeschriftung td {
    padding-left: 10px;
    background-color: $colorCorporateLightBrown;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    border-right: solid 1px $colorCorporateBrown;
    border-bottom: solid 1px $colorCorporateBrown;
}
.reportbeschriftung td.last {
    border-right: none;
}
/* Ende Reportzeilen */
